.statusprojeto{
    box-shadow: 0px 1px 12px 0px #666;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: 0 !important;
    position: absolute;
    bottom: -31px;
    padding: 2px 15px !important;
    right: 15px;
}
.statusprojeto>h4{
    margin: 2px 0;
}
.statusprojeto span{
    margin-right: 5px;
}
@media (max-width: 768px){
    .statusprojeto{
        display:none;
    }
}